import PromocodeCreate from 'Marketing/page/Promocode/PromocodeCreate/PromocodeCreate.vue'
import PromocodeList from 'Marketing/page/Promocode/PromocodeList/PromocodeList.vue'
import PromocodeEdit from 'Marketing/page/Promocode/PromocodeEdit/PromocodeEdit.vue'

import InstallmentCreate from 'Marketing/page/Installment/InstallmentCreate/InstallmentCreate.vue'
import InstallmentList from 'Marketing/page/Installment/InstallmentList/InstallmentList.vue'
import InstallmentEdit from 'Marketing/page/Installment/InstallmentEdit/InstallmentEdit.vue'

import MailingTemplateList from 'Marketing/page/MailingTemplate/MailingTemplateList/MailingTemplateList.vue'
import MailingTemplateCreate from 'Marketing/page/MailingTemplate/MailingTemplateCreate/MailingTemplateCreate.vue'
import MailingTemplateEdit from 'Marketing/page/MailingTemplate/MailingTemplateEdit/MailingTemplateEdit.vue'

import StaticQRCodeList from 'Marketing/page/QRcode/Static/QRcodeList/StaticQRcodeList.vue'
import StaticQRCodeCreate from 'Marketing/page/QRcode/Static/QRcodeCreate/StaticQRcodeCreate.vue'
import StaticQRCodeEdit from 'Marketing/page/QRcode/Static/QRcodeEdit/StaticQRcodeEdit.vue'

import DynamicQRCodeList from 'Marketing/page/QRcode/Dynamic/QRcodeList/DynamicQRcodeList.vue'
import DynamicQRCodeEdit from 'Marketing/page/QRcode/Dynamic/QRcodeEdit/DynamicQRcodeEdit'
import DynamicQRCodeCreate from 'Marketing/page/QRcode/Dynamic/QRcodeCreate/DynamicQRCodeCreate'

export default [
  {
    path: '/promocode',
    name: 'PromocodeList',
    component: PromocodeList,
    meta: {
      permission: {
        service: 'promocode',
        permissions: ['specification-view'],
      },
      search: {
        name: 'Промокоды',
        icon: 'container',
      },
      failPath: '/censor',
    },
  },
  {
    path: '/promocode/create',
    name: 'PromocodeCreate',
    component: PromocodeCreate,
    meta: {
      permission: {
        service: 'promocode',
        permissions: ['specification-create'],
      },
      search: {
        name: 'Создать промокод',
        icon: 'container',
      },
      failPath: '/censor',
    },
  },
  {
    path: '/promocode/:promocode_id',
    name: 'PromocodeEdit',
    component: PromocodeEdit,
    meta: {
      permission: {
        service: 'promocode',
        permissions: ['specification-update'],
      },
      failPath: '/censor',
    },
  },
  {
    path: '/installment',
    name: 'InstallmentList',
    component: InstallmentList,
    meta: {
      permission: {
        service: 'payplan',
        permissions: ['installment-view'],
      },
      search: {
        name: 'Рассрочки',
        icon: 'idcard',
      },
      failPath: '/censor',
    },
  },
  {
    path: '/installment/create',
    name: 'InstallmentCreate',
    component: InstallmentCreate,
    meta: {
      permission: {
        service: 'payplan',
        permissions: ['installment-create'],
      },
      search: {
        name: 'Создать рассрочку',
        icon: 'idcard',
      },
      failPath: '/censor',
    },
  },
  {
    path: '/installment/:installment_id',
    name: 'InstallmentEdit',
    component: InstallmentEdit,
    meta: {
      permission: {
        service: 'payplan',
        permissions: ['installment-update'],
      },
      failPath: '/censor',
    },
  },

  {
    path: '/mailing-template',
    name: 'MailingTemplateList',
    component: MailingTemplateList,
    meta: {
      permission: {
        service: 'jam',
        permissions: ['template-view'],
      },
      search: {
        name: 'Шаблоны рассылок',
        icon: 'mail',
      },
      failPath: '/censor',
    },
  },
  {
    path: '/mailing-template/create',
    name: 'MailingTemplateCreate',
    component: MailingTemplateCreate,
    meta: {
      permission: {
        service: 'jam',
        permissions: ['template-create'],
      },
      search: {
        name: 'Создать шаблон рассылки',
        icon: 'mail',
      },
      failPath: '/censor',
    },
  },
  {
    path: '/mailing-template/:template_id',
    name: 'MailingTemplateEdit',
    component: MailingTemplateEdit,
    meta: {
      permission: {
        service: 'jam',
        permissions: ['template-update'],
      },
      failPath: '/censor',
    },
  },
  {
    path: '/static-qr-codes',
    name: 'StaticQRCodeList',
    component: StaticQRCodeList,
    meta: {
      permission: {
        service: 'payplan',
        permissions: ['installment-view'],
      },
      search: {
        name: 'QR коды',
        icon: 'qrcode',
      },
      failPath: '/censor',
    },
  },
  {
    path: '/static-qr-create',
    name: 'StaticQRCodeCreate',
    component: StaticQRCodeCreate,
    meta: {
      permission: {
        service: 'payplan',
        permissions: ['installment-view'],
      },
      search: {
        name: 'QR создать',
        icon: 'qrcode',
      },
      failPath: '/censor',
    },
  },
  {
    path: '/static-qr-code/:id',
    name: 'StaticQRCodeEdit',
    component: StaticQRCodeEdit,
    meta: {
      permission: {
        service: 'payplan',
        permissions: ['installment-view'],
      },
      failPath: '/censor',
    },
  },
  {
    path: '/dynamic-qr-codes',
    name: 'DynamicQRCodeList',
    component: DynamicQRCodeList,
    meta: {
      permission: {
        service: 'payplan',
        permissions: ['installment-view'],
      },
      search: {
        name: 'QR коды',
        icon: 'qrcode',
      },
      failPath: '/censor',
    },
  },
  {
    path: '/dynamic-qr-create',
    name: 'DynamicQRCodeCreate',
    component: DynamicQRCodeCreate,
    meta: {
      permission: {
        service: 'payplan',
        permissions: ['installment-view'],
      },
      search: {
        name: 'QR создать',
        icon: 'qrcode',
      },
      failPath: '/censor',
    },
  },
  {
    path: '/dynamic-qr-code/:id',
    name: 'DynamicQRCodeEdit',
    component: DynamicQRCodeEdit,
    meta: {
      permission: {
        service: 'payplan',
        permissions: ['installment-view'],
      },
      failPath: '/censor',
    },
  },
]
