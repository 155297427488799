<template>
  <div>
    <div class="settings-wrap">
      <h2>Создание рассрочки</h2>
      <router-link :to="{ name: 'InstallmentList' }">
        <a-button>Назад</a-button>
      </router-link>
    </div>
    <a-form-model
      ref="form"
      :model="installmentForm"
      :rules="installmentFormRules"
      @submit="createInstallment"
    >
      <a-form-model-item prop="title.ru" label="Заголовок на русском">
        <a-input v-model="installmentForm.title.ru" />
      </a-form-model-item>
      <a-form-model-item prop="categories" label="Зона действия рассрочки">
        <div class="list-wrap">
          <div
            v-for="(category, index) in installmentForm.categories"
            :key="index"
          >
            <a-tag closable @close.prevent="removeCategoryHandler(index)">
              {{ category.title.ru }}
            </a-tag>
          </div>
        </div>

        <category-selector
          :drawer-title="'Выберите категорию товара'"
          :drawer-width="'85%'"
          :multiple="true"
          :only-third-lvl="true"
          v-model="installmentForm.categories"
        />
      </a-form-model-item>
      <a-form-model-item prop="commission" label="Комиссионные">
        <a-input-number
          v-model="installmentForm.commission"
          :min="1"
          block
          :formatter="(value) => `${value}%`"
          :parser="(value) => value.replace('%', '')"
          :max="100"
          :precision="2"
        />
      </a-form-model-item>
      <a-form-model-item prop="duration" label="Продолжительность ( месяцы )">
        <!--        <a-input-number-->
        <!--          v-model="installmentForm.duration"-->
        <!--          block-->
        <!--          :min="3"-->
        <!--          :max="99"-->
        <!--        />-->
        <a-select :default-value="6" v-model="installmentForm.duration">
          <a-select-option :value="6"> 6</a-select-option>
          <a-select-option :value="12"> 12</a-select-option>
          <a-select-option :value="24"> 24</a-select-option>
          <a-select-option :value="48"> 48</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop="min_product_price" label="Мин. сумма товара">
        <a-input-number
          :precision="0"
          :min="0"
          :max="99999999"
          v-model="installmentForm.min_product_price"
          block
        />
      </a-form-model-item>
      <a-form-model-item prop="max_product_price" label="Макс. сумма товара">
        <a-input-number
          :min="100"
          :max="99999999"
          :precision="0"
          v-model="installmentForm.max_product_price"
          block
        />
      </a-form-model-item>
      <a-form-model-item prop="end_at" label="Даты:">
        <a-range-picker
          :locale="locale"
          :disabled-date="disabledDate"
          :format="'DD MMM YYYY'"
          @change="onDateRangeChange"
          :placeholder="['С:', 'По:']"
          :value="[installmentForm.start_at, installmentForm.end_at]"
          :allow-clear="false"
        ></a-range-picker>
      </a-form-model-item>

      <a-form-model-item prop="background_color" label="Цвет фона">
        <a-input
          v-model="installmentForm.background_color"
          class="color__input"
        >
          <template slot="addonBefore">
            <div
              :style="{ backgroundColor: installmentForm.background_color }"
              class="color__preview"
            ></div>
          </template>
        </a-input>
      </a-form-model-item>
      <a-form-model-item prop="text_color" label="Цвет текста">
        <a-input v-model="installmentForm.text_color" class="color__input">
          <template slot="addonBefore">
            <div
              :style="{ backgroundColor: installmentForm.text_color }"
              class="color__preview"
            ></div>
          </template>
        </a-input>
      </a-form-model-item>
      <a-form-model-item
        label="Превью рассрочки"
        v-if="installmentForm.title.ru"
      >
        <div
          class="installment__content"
          :style="{ backgroundColor: installmentForm.background_color }"
        >
          <p
            class="installment__text"
            :style="{ color: installmentForm.text_color }"
          >
            {{ installmentForm.title.ru }}
          </p>
        </div>
      </a-form-model-item>
      <a-button type="primary" html-type="submit" class="form__button">
        Сохранить
      </a-button>
    </a-form-model>
  </div>
</template>

<script>
import CategorySelector from 'Core/components/Selector/CategorySelector'

import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import { installmentFormRules } from './constants'

import { removeFromArrayByIndex } from 'Core/modules/helpers'

import moment from 'moment'
import locale from 'ant-design-vue/es/date-picker/locale/ru_RU'

export default {
  components: {
    CategorySelector,
  },
  watch: {
    'installmentForm.categories': {
      handler() {
        this.$refs.form.validateField('categories')
      },
    },
  },
  data() {
    return {
      locale,
      installmentFormRules,
      installmentForm: {
        background_color: '#000000',
        min_product_price: 0,
        max_product_price: 100,
        categories: [],
        commission: 1,
        duration: null,
        end_at: null,
        start_at: null,
        text_color: '#ffffff',
        title: { kz: null, ru: null },
      },
    }
  },
  methods: {
    disabledDate(current) {
      return current && current < moment(new Date())
    },
    onDateRangeChange(date) {
      this.installmentForm.start_at = moment(date[0]).format(
        'YYYY-MM-DDT00:00:00Z',
      )
      this.installmentForm.end_at = moment(date[1]).format(
        'YYYY-MM-DDT23:59:59Z',
      )
    },
    async createInstallment(event) {
      event.preventDefault()
      this.$refs.form.validate(async (result) => {
        if (result) {
          try {
            const { id } = await this.$API.Payplan.createInstallment(
              this.prepareForRequestHandler(),
            )
            showSuccessMsg('Рассрочка создана.')
            await this.$router.push({
              name: 'InstallmentEdit',
              params: {
                installment_id: id,
              },
            })
          } catch (error) {
            showErrorMsg(error.message)
          }
        }
      })
    },
    prepareForRequestHandler() {
      const params = { ...this.installmentForm }
      params.title.kz = params.title.ru
      params.commission = params.commission * 100
      params.categories = params.categories.map((item) => item.slug)
      return params
    },
    removeCategoryHandler(index) {
      removeFromArrayByIndex(this.installmentForm.categories, index)
    },
  },
}
</script>

<style lang="scss" scoped>
.ant-form-item {
  margin-bottom: 10px;
}

.ant-input-number {
  width: 200px;
}

.form__button {
  margin-top: 30px !important;
}

::v-deep {
  .ant-form-item-label {
    font-weight: 600 !important;
  }
}

.color {
  &__preview {
    width: 15px;
    height: 15px;
    border: 1px solid black;
  }

  &__option {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.installment {
  &__content {
    padding: 5px 30px;
    width: fit-content;
    border-radius: 5px;
  }

  &__text {
    margin: 0;
    padding: 0;
    font-weight: 600;
  }
}

.list-wrap {
  display: flex;
  flex-wrap: wrap;

  & span {
    padding: 7px 12px;
    font-size: 0.9rem;
  }

  margin-bottom: 10px;
}
</style>
