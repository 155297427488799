<template>
  <div class="label">
    <p>
      <a-tag :color="MARKET_TYPES[market.market_type].color">
        {{ MARKET_TYPES[market.market_type].title }}
      </a-tag>
      :
      <code v-if="market.merchant_sku"> {{ market.merchant_sku }} </code>
      <span v-else>
        <code>отсутствует </code>
        <a-button @click="addSkuHandler(MARKET_TYPES[market.market_type])">
          <a-icon type="plus" />
        </a-button>
      </span>
    </p>
    <a-modal
      v-model="modalIsShown"
      title="Укажите SKU"
      cancel-text="Отмена"
      @ok="setSkuValue"
      :ok-button-props="{
        props: { disabled: skuValue === null || typeof skuValue !== 'number' },
      }"
    >
      <h4 v-if="MARKET_TYPES[market.market_type].title">
        {{ MARKET_TYPES[market.market_type].title }}
      </h4>
      <a-form-model-item
        label="После сохранения нельзя редактировать. Указывайте точный sku."
      >
        <a-input-number style="width: 100%" v-model="skuValue" />
      </a-form-model-item>
    </a-modal>
  </div>
</template>

<script>
import { MARKET_TYPES } from './constants'
import { showErrorMsg } from 'Core/utils'

export default {
  name: 'MarketBpSku',
  props: {
    market: {},
    bp_sku: {
      required: true,
    },
  },
  data() {
    return {
      MARKET_TYPES,
      modalIsShown: false,
      skuValue: null,
      currentMarket: null,
    }
  },
  methods: {
    addSkuHandler(marketInfo) {
      this.modalIsShown = true
      this.skuValue = null
      this.currentMarket = marketInfo
    },
    async setSkuValue() {
      try {
        await this.$API.BPM.postSkuToMarket({
          bp_sku: this.bp_sku,
          market_type: this.currentMarket.value,
          merchant_sku: this.skuValue.toString(),
        })
        this.modalIsShown = false
        this.$emit('refreshList')
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.label {
  margin-bottom: 10px;
}
</style>
