import { createMap } from '../utils'

export const core = {
  namespaced: true,
  state: () => ({
    cities: {},
    servicePoints: {},
    categoryParent: [],
    merchantProfileInfoList: {},
  }),
  mutations: {
    UPDATE_CITIES: (state, cities) => {
      state.cities = cities
    },
    UPDATE_CATEGORY_PARENT: (state, categoryParent) => {
      state.categoryParent = categoryParent
    },
    UPDATE_SERVICE_POINTS: (state, servicePoints) => {
      state.servicePoints = servicePoints
    },
    UPDATE_MERCHANT_INFO_LIST: (state, merchantProfileInfo) => {
      state.merchantProfileInfoList[merchantProfileInfo.id] =
        merchantProfileInfo
    },
  },
  actions: {
    updateCities: async function ({ commit }) {
      const data = await this._vm.$API.ConfigDiscovery.getCities()
      if (data) {
        const adaptedCities = createMap('id', data.cities)
        commit('UPDATE_CITIES', adaptedCities)
      }
    },
    getCategoryParent: async function ({ commit }) {
      const categoryParent = await this._vm.$API.Storefront.getCategoryList({
        level: 1,
      })
      categoryParent ? commit('UPDATE_CATEGORY_PARENT', categoryParent) : []
    },
  },
  getters: {
    cities: (state) => state.cities,
    sortedCities: (state) => {
      return Object.values(state.cities).sort((a, b) => {
        if (
          a.title.ru.slice(a.title.ru.indexOf(' ') + 1) <
          b.title.ru.slice(b.title.ru.indexOf(' ') + 1)
        ) {
          return -1
        }
        if (
          a.title.ru.slice(a.title.ru.indexOf(' ') + 1) >
          b.title.ru.slice(b.title.ru.indexOf(' ') + 1)
        ) {
          return 1
        }
        return 0
      })
    },
    servicePoints: (state) => state.servicePoints,
    categoryParent: (state) => state.categoryParent,
    merchantProfileInfoList: (state) => state.merchantProfileInfoList,
  },
}
