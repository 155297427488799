
































import { showErrorMsg, showSuccessMsg } from '@/Core/utils'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import {
  RESET_PASSWORD_RULES,
  RESET_PASSWORD_STATE,
  CHANGE_PASSWORD_RULES,
} from './constants'

enum FORM_TYPES {
  CHANGE = 'change',
  RESET = 'reset',
}

@Component
class ChangePasswordModal extends Vue {
  @Prop({ default: false })
  value: boolean
  @Prop({ default: null })
  phoneNumber: string
  @Prop({ type: String, default: 'reset' })
  type: FORM_TYPES

  formState = RESET_PASSWORD_STATE
  // rules = this.type === 'change' RESET_PASSWORD_RULES

  get rules() {
    return this.type === FORM_TYPES.CHANGE
      ? CHANGE_PASSWORD_RULES
      : RESET_PASSWORD_RULES
  }

  $refs: any

  @Watch('phoneNumber', { immediate: true })
  onPhoneChange() {
    this.formState.phone = this.phoneNumber
  }

  closeModal() {
    this.$emit('input', false)
    this.formState = {
      otp: '',
      password: '',
      confirmedPassword: '',
      phone: '',
    }
  }

  async onSubmit() {
    this.$refs.form.validate(async (valid) => {
      if (!valid) return
      if (this.formState.password !== this.formState.confirmedPassword) {
        showErrorMsg('Пароли не соответствуют')
        return
      }

      try {
        if (this.type === FORM_TYPES.RESET) {
          await this.$API.SSO.recoverPasswordByPhone(this.formState)
          showSuccessMsg('Пароль успешно восстановлен')
        } else {
          await this.$API.SSO.updatePassword(this.formState.password)
          showSuccessMsg('Пароль успешно обновлен')
        }
        this.closeModal()
      } catch (e) {
        showErrorMsg(e.message)
      }
    })
  }
}

export default ChangePasswordModal
