export const columnNames = [
  {
    title: '№ заказа',
    dataIndex: 'order_id',
    scopedSlots: { customRender: 'orderId' },
  },
  {
    title: '№ заказа партнера',
    dataIndex: 'merchant_order_id',
    scopedSlots: { customRender: 'merchantOrderId' },
  },
  {
    title: 'Сумма',
    dataIndex: 'total_price',
    scopedSlots: { customRender: 'totalPrice' },
    width: '170px',
  },
  {
    title: 'Оплата',
    dataIndex: 'payment',
    scopedSlots: { customRender: 'payment' },
  },
  {
    title: 'Промокод',
    dataIndex: 'promo_code',
    scopedSlots: { customRender: 'promo_code' },
    width: '170px',
  },
  {
    title: 'Дата завершения заказа',
    dataIndex: 'end_time',
    scopedSlots: { customRender: 'endDate' },
  },
]
