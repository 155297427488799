import { ApiServiceClass } from 'Core/modules/API/ApiServiceClass'
import { Api } from 'Core/modules/API/ApiServiceDecorator'

/**
 *  Сервис генерации qr кодов
 * <br> Swagger
 * <br> {@link https://api.mp-test.airba.dev/qr/swagger/index.html} */
export class QRCodeService extends ApiServiceClass {
  constructor(axios) {
    super({
      axios: axios,
      serviceUrl: 'qr-generator',
    })
  }

  @Api
  async getStaticQRCodeList(params): Promise<any> {
    const response = await this.GET('/v1/qrs', params)
    return response
  }

  @Api
  async getStaticQRCodeById(id) {
    const response = await this.GET(`/v1/qrs/${id}`)
    return response
  }

  @Api
  async createStaticQRCode(params) {
    const response = await this.POST(`/v1/qrs`, params)
    return response
  }

  @Api
  async updateStaticQRCode(id, params) {
    const response = await this.PATCH(`/v1/qrs/${id}`, params)
    return response
  }

  @Api
  async deleteStaticQRCode(id) {
    const response = await this.DELETE(`/v1/qrs/${id}`)
    return response
  }

  @Api
  async getDynamicQRCodeList(params): Promise<any> {
    const response = await this.GET('/v1/qrs-dynamic', params)
    return response
  }

  @Api
  async createDynamicQRCode(params): Promise<any> {
    const response = await this.POST('/v1/qrs-dynamic', params)
    return response
  }

  @Api
  async getDynamicQRCodeById(id) {
    const response = await this.GET(`/v1/qrs-dynamic/${id}`)
    return response
  }

  @Api
  async updateDynamicQRCode(id, params) {
    const response = await this.PATCH(`/v1/qrs-dynamic/${id}`, params)
    return response
  }

  @Api
  async deleteDynamicQRCode(id) {
    const response = await this.DELETE(`/v1/qrs-dynamic/${id}`)
    return response
  }
}
