export const roles = {
  superAdmin: 'Супер Админ',
  moderator: 'Модератор',
  user: 'Пользователь',
}

export const langs = { kz: 'Казахский', ru: 'Русский', en: 'Английский' }

export const RESET_PASSWORD_RULES = {
  password: { type: 'string', required: true, fullField: 'Пароль' },
  confirmedPassword: { type: 'string', required: true, fullField: 'Пароль' },
  otp: { type: 'string', required: true, fullField: 'OTP' },
  phone: { type: 'string', required: true, fullField: 'OTP' },
}

export const CHANGE_PASSWORD_RULES = {
  password: { type: 'string', required: true, fullField: 'Пароль' },
  confirmedPassword: { type: 'string', required: true, fullField: 'Пароль' },
}

export const RESET_PASSWORD_STATE = {
  otp: '',
  password: '',
  confirmedPassword: '',
  phone: '',
}
