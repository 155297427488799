import { ApiServiceClass } from 'Core/modules/API/ApiServiceClass'
import { Api } from 'Core/modules/API/ApiServiceDecorator'
import { BusinessLogicException } from 'Core/modules/API/@types/BusinessLogicException'

/**
 *  Сервис управления акциями и рассрочками
 * <br> Swagger
 * <br> {@link https://api.mp-test.airba.dev/payplan/swagger/index.html} */
export class PayplanService extends ApiServiceClass {
  constructor(axios) {
    super({
      axios: axios,
      serviceUrl: 'payplan',
    })
  }

  @Api
  async getInstallmentList(params): Promise<any> {
    const response = await this.GET('/v1/installments', params)
    return response
  }

  @Api
  async createInstallment(payload) {
    const response = await this.POST('/v1/installments', payload)
    return response
  }

  async updateInstallmentById(id, payload) {
    try {
      const response = await this.PUT<{ data: any; success: boolean }>(
        `/v1/installments/${id}`,
        payload,
      )
      if (response.success) {
        return response
      } else if (
        response?.data?.error?.message.includes('start_at must be in future')
      ) {
        throw new BusinessLogicException('start_at must be in future')
      } else {
        throw new BusinessLogicException(
          response?.data.error?.message
            ? response?.data.error?.message
            : 'updateInstallmentById',
        )
      }
    } catch (error) {
      throw new Error(error.transformErrorMessage())
    }
  }

  @Api
  async getInstallmentById(id) {
    const response = await this.GET(`/v1/installments/${id}`)
    return response
  }

  @Api
  async launchInstallmentById(id) {
    const response = await this.PUT(`/v1/installments/${id}/enabled`, {
      is_enabled: true,
    })
    return response
  }
}
