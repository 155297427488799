export const columnNames = [
  {
    title: 'sku Airba',
    dataIndex: 'bp_sku',
    key: 'bp_sku',
    scopedSlots: { customRender: 'bp_sku' },
    align: 'right',
  },
  {
    title: 'Маркеты',
    dataIndex: 'market_types',
    key: 'market_types',
    scopedSlots: { customRender: 'market_types' },
  },
  {
    title: 'Название(может быть неактуальной)',
    dataIndex: 'title.ru',
    key: 'title.ru',
  },
]

export const MARKET_TYPES = {
  kaspi: {
    title: 'KASPI',
    value: 'kaspi',
    color: 'darkred',
  },
  ozon: {
    title: 'OZON',
    value: 'ozon',
    color: 'darkblue',
  },
  technodom: {
    title: 'TECHNODOM',
    value: 'technodom',
    color: 'darkorange',
  },
}
