<template>
  <div>
    <div class="settings-wrap">
      <h2>Список статичных QR кодов</h2>
      <a-button @click="gotoCreateQR">Создать новый QR код</a-button>
    </div>
    <a-table
      :columns="tableColumns"
      :data-source="QRcodeList"
      :scroll="{ x: true }"
      :locale="{ emptyText: 'Данные не найдены ):' }"
      :row-key="({ _id }) => _id"
      :pagination="pagination"
      :loading="isLoading"
      @change="onPageChange"
      bordered
    >
      <div slot="id" slot-scope="id">
        <router-link
          :to="{
            name: 'StaticQRCodeEdit',
            params: {
              id: id,
            },
          }"
        >
          {{ id | shortID(5) }}
        </router-link>
      </div>
      <div slot="info" slot-scope="info, record">
        <h4>{{ info }}</h4>
        <p class="info">{{ record.description }}</p>
      </div>
      <div slot="link" slot-scope="link">
        <a :href="addPrefix(link)" target="_blank">
          <code>{{ link }}</code>
          <a-icon type="link" />
        </a>
      </div>
      <div slot="dataSrc" slot-scope="dataSrc">
        <img :src="`data:image/png;base64,${dataSrc}`" />
      </div>
    </a-table>
  </div>
</template>

<script>
import { tableColumns } from './constants'
import { showErrorMsg } from 'Core/utils'

export default {
  name: 'QRcodeList',
  data() {
    return {
      tableColumns,
      QRcodeList: null,
      isLoading: false,
      pagination: {
        current: 1,
        total: 0,
        defaultPageSize: 10,
      },
      queryParams: {
        limit: 10,
        page: 1,
      },
    }
  },
  created() {
    this.getQRcodeList()
  },
  methods: {
    async getQRcodeList() {
      try {
        this.isLoading = true
        const { data, count } = await this.$API.QRCode.getStaticQRCodeList(
          this.queryParams,
        )
        this.QRcodeList = data
        this.pagination.total = count
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isLoading = false
      }
    },
    onPageChange({ current }) {
      this.queryParams.page = current
      this.pagination.current = current
      this.getQRcodeList()
    },
    gotoCreateQR() {
      this.$router.push({
        name: 'StaticQRCodeCreate',
      })
    },
    addPrefix(url) {
      // если укажут url типа youtube.com вместо https://youtube.com
      // браузер будет перенаправлять по относительному пути. Чтобы такого не было, добавляем префикс(если его нет)
      // eslint-disable-next-line
      return url.match(/^.{3,5}\:\/\//) ? url : `http://${url}`
    },
  },
}
</script>

<style scoped></style>
