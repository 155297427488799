import ModelList from 'Product/pages/Model/ModelList/ModelList'
import ModelCreate from 'Product/pages/Model/ModelCreate/ModelCreate'
import ModelEdit from 'Product/pages/Model/ModelEdit/ModelEdit'

import AttributeList from 'Product/pages/Model/Attributes/AttributeList/AttributeList'
import AttributeCreate from 'Product/pages/Model/Attributes/AttributeCreate/AttributeCreate'
import AttributeEdit from 'Product/pages/Model/Attributes/AttributeEdit/AttributeEdit'

import AttributeGroupList from 'Product/pages/Model/Attributes/Group/GroupList/GroupList'
import AttributeGroupCreate from 'Product/pages/Model/Attributes/Group/GroupCreate/GroupCreate'
import AttributeGroupEdit from 'Product/pages/Model/Attributes/Group/GroupEdit/GroupEdit'

import BaseProductList from 'Product/pages/BaseProduct/BaseProductList/BaseProductList'
import BaseProductEdit from 'Product/pages/BaseProduct/BaseProductEdit/BaseProductEdit'
import BaseProductCreate from 'Product/pages/BaseProduct/BaseProductCreate/BaseProductCreate'

import BaseProductGroupList from 'Product/pages/BaseProductGroup/BaseProductGroupList/BaseProductGroupList'
import BaseProductGroupCreate from 'Product/pages/BaseProductGroup/BaseProductGroupCreate/BaseProductGroupCreate'
import BaseProductGroupEdit from 'Product/pages/BaseProductGroup/BaseProductGroupEdit/BaseProductGroupEdit'
import MultipleBaseProductCreate from 'Content/pages/MultipleBaseProductCreate/MultipleBaseProductCreate'

import MatchedSkuList from 'Product/pages/MatchedSku/MatchedSkuList'

export default [
  {
    path: '/models',
    name: 'ModelList',
    component: ModelList,
    meta: {
      permission: {
        service: 'storefront',
        permissions: ['model-view'],
      },
      search: {
        name: 'Модели',
        icon: 'database',
      },
      failPath: '/censor',
    },
  },
  {
    path: '/model-create',
    name: 'ModelCreate',
    component: ModelCreate,
    meta: {
      permission: {
        service: 'storefront',
        permissions: ['model-create'],
      },
      search: {
        name: 'Создать модель',
        icon: 'database',
      },
      failPath: '/censor',
    },
  },
  {
    path: '/model-edit/:id',
    name: 'ModelEdit',
    component: ModelEdit,
    meta: {
      permission: {
        service: 'storefront',
        permissions: ['model-update'],
      },
      failPath: '/censor',
    },
  },
  {
    path: '/model/:id/attributes',
    name: 'AttributeList',
    component: AttributeList,
    meta: {
      permission: {
        service: 'storefront',
        permissions: ['model-view'],
      },
      failPath: '/censor',
    },
  },
  {
    path: '/model/:id/attribute-create',
    name: 'AttributeCreate',
    component: AttributeCreate,
    meta: {
      permission: {
        service: 'storefront',
        permissions: ['model-update'],
      },
      failPath: '/censor',
    },
  },
  {
    path: '/model/:modelId/attribute=:id',
    name: 'AttributeEdit',
    component: AttributeEdit,
    meta: {
      permission: {
        service: 'storefront',
        permissions: ['model-update'],
      },
      failPath: '/censor',
    },
  },
  {
    path: '/model/:modelId/attribute/groups',
    name: 'AttributeGroupList',
    component: AttributeGroupList,
    meta: {
      permission: {
        service: 'storefront',
        permissions: ['model-view'],
      },
      failPath: '/censor',
    },
  },
  {
    path: '/model/:modelId/attribute/group-create',
    name: 'AttributeGroupCreate',
    component: AttributeGroupCreate,
    meta: {
      permission: {
        service: 'storefront',
        permissions: ['model-update'],
      },
      failPath: '/censor',
    },
  },
  {
    path: '/model/:modelId/attribute/group=:id',
    name: 'AttributeGroupEdit',
    component: AttributeGroupEdit,
    meta: {
      permission: {
        service: 'storefront',
        permissions: ['model-update'],
      },
      failPath: '/censor',
    },
  },
  {
    path: '/base-products',
    name: 'BaseProductList',
    component: BaseProductList,
    meta: {
      permission: {
        service: 'storefront',
        permissions: ['base-product-view'],
      },
      search: {
        name: 'Базовые продукты',
        icon: 'book',
      },
      failPath: '/censor',
    },
  },
  {
    path: '/base-product/:id',
    name: 'BaseProductEdit',
    component: BaseProductEdit,
    meta: {
      permission: {
        service: 'storefront',
        permissions: ['base-product-update'],
      },
      failPath: '/censor',
    },
  },
  {
    path: '/base-product-create',
    name: 'BaseProductCreate',
    component: BaseProductCreate,
    meta: {
      permission: {
        service: 'storefront',
        permissions: ['base-product-create'],
      },
      search: {
        name: 'Создать базовый продукт',
        icon: 'book',
      },
      failPath: '/censor',
    },
  },
  {
    path: '/base-product-groups',
    name: 'BaseProductGroupList',
    component: BaseProductGroupList,
    meta: {
      permission: {
        service: 'storefront',
        permissions: ['group-view'],
      },
      search: {
        name: 'Группы базовых продуктов',
        icon: 'appstore',
      },
      failPath: '/censor',
    },
  },
  {
    path: '/base-product-groups/create',
    name: 'BaseProductGroupCreate',
    component: BaseProductGroupCreate,
    meta: {
      permission: {
        service: 'storefront',
        permissions: ['group-create'],
      },
      search: {
        name: 'Создать группу базовых продуктов',
        icon: 'appstore',
      },
      failPath: '/censor',
    },
  },
  {
    path: '/base-product-groups/:group_id',
    name: 'BaseProductGroupEdit',
    component: BaseProductGroupEdit,
    meta: {
      permission: {
        service: 'storefront',
        permissions: ['group-update'],
      },
      failPath: '/censor',
    },
  },
  {
    path: '/multiple-base-product-create',
    name: 'MultipleBaseProductCreate',
    component: MultipleBaseProductCreate,
    meta: {
      permission: {
        service: 'role',
        permissions: ['role-assign'],
      },
      failPath: '/censor',
    },
  },
  {
    path: '/matched-sku',
    name: 'MatchedSkuList',
    component: MatchedSkuList,
    meta: {
      permission: {
        service: 'role',
        permissions: ['role-assign'],
      },
      failPath: '/censor',
    },
  },
]
