import { Quill } from 'vue-quill-editor/dist/ssr'

const Video = Quill.import('formats/video')

export class VideoWrapper extends Video {
  static create(value) {
    let child = super.create(value)

    const node = document.createElement('div')
    node.classList.add('video-container')
    node.appendChild(child)
    return node
  }
}
