<template>
  <div>
    <div class="settings-wrap">
      <h2>Комиссия по категориям</h2>
      <set-category-commission
        :category-i-i-i="categoryIII"
        @UpdateCommissionList="UpdateCommissionList"
      />
    </div>
    <div class="settings-wrap">
      <a-button @click="$router.push({ name: 'ComissionNew' })">
        Временная страница всего списка
      </a-button>
    </div>
    <div>
      <h4>Категории 3 уровня</h4>
      <a-table
        :columns="columnNames"
        :data-source="dataSource"
        :scroll="{ x: true }"
        :locale="{ emptyText: 'Данные не найдены ):' }"
        :row-key="({ id }) => id"
        :loading="isLoading"
        :pagination="pagination"
        @change="onPageChange"
        bordered
      >
        <div slot="rate" slot-scope="rate, record">
          <a-tag color="green" class="rate-box">
            <span>{{ rate / 100 }}</span>
            %
          </a-tag>
          <a-icon @click="editHandler(record)" class="edit-box" type="edit" />
        </div>
        <div slot="updatedAt" slot-scope="updatedAt">
          {{ updatedAt | formatDate }}
        </div>
      </a-table>
    </div>
    <a-modal
      v-model="modalIsShown"
      title="Укажите значение от 0-100%"
      cancel-text="Отмена"
      @ok="handleUpdateCategoryRate"
      :ok-button-props="{
        props: { disabled: !currentCat.value },
      }"
    >
      <h4 v-if="currentCat.category_title">
        {{ currentCat.category_title.ru }}
      </h4>
      <a-form-model-item label="Сумма комиссии">
        <a-input-number
          v-model="rate"
          :min="0"
          block
          :formatter="(value) => `${value}%`"
          :parser="(value) => value.replace('%', '')"
          :max="100"
          :precision="2"
        />
      </a-form-model-item>
    </a-modal>
  </div>
</template>

<script>
import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import SetCategoryCommission from '../component/SetCategoryCommission.vue'
import { columnNames } from './constants'

export default {
  name: 'ComissionView',
  data: () => ({
    columnNames,
    dataSource: [],
    isLoading: true,
    modalIsShown: false,
    categoryIII: [],
    pagination: {
      current: 1,
      total: 0,
      defaultPageSize: 10,
    },
    filter: {
      page: 1,
      limit: 10,
    },
    currentCat: {},
    rate: 0,
  }),
  methods: {
    async initialize() {
      try {
        this.isLoading = true
        await this.getCommission()
        this.categoryIII = await this.$API.Storefront.getCategoryList({
          level: 3,
        })
        this.isLoading = false
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isLoading = false
      }
    },
    UpdateCommissionList() {
      this.initialize()
    },
    async getCommission() {
      try {
        const { commissions, count } =
          await this.$API.Commissions.getCommissionsList(this.filter)
        this.dataSource = commissions
        this.pagination.total = count
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    onPageChange({ current }) {
      this.filter.page = current
      this.pagination.current = current
      this.initialize()
    },
    editHandler(category) {
      this.currentCat = category
      this.rate = category.value / 100
      this.modalIsShown = true
    },
    async handleUpdateCategoryRate() {
      const params = {
        value: this.rate ? this.rate * 100 : 0,
        category_title: {
          ru: this.currentCat.category_title.ru,
          kz: this.currentCat.category_title.kz,
        },
        category: this.currentCat.category,
        merchant_id: null,
      }
      await this.$API.Commissions.updateCommission(this.currentCat.id, params)
      showSuccessMsg('Успешно обновили комиссию')
      await this.initialize()
      this.modalIsShown = false
    },
  },
  mounted() {
    this.initialize()
  },
  components: {
    SetCategoryCommission,
  },
}
</script>

<style scoped lang="scss">
.cat-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 20px;
  margin: 10px;
  font-weight: bold;
  border-bottom: 2px solid blanchedalmond;
}

.rate-box {
  padding: 7px 12px;

  & span {
    font-weight: bold;
    margin-right: 5px;
    font-size: 1.1rem;
  }
}

.edit-box {
  cursor: pointer;
  color: orange;

  &:hover {
    color: orangered;
  }
}
</style>
