<template>
  <div>
    <div class="settings-wrap">
      <h2>Создание QR ссылок</h2>
      <a-button
        @click="
          () =>
            $router.push({
              name: 'StaticQRCodeList',
            })
        "
      >
        {{ $t('common.back') }}
      </a-button>
    </div>
    <a-form-model
      ref="form"
      :model="formState"
      :rules="rules"
      @submit="onValidate"
    >
      <a-form-model-item prop="abbrev" label="Наименование (Слаг)">
        <a-input v-model="formState.name" @focusout="toUppercase" />
      </a-form-model-item>

      <a-form-model-item prop="description" label="Описание">
        <a-input v-model="formState.description" />
      </a-form-model-item>

      <a-form-model-item prop="link" label="Ссылка">
        <a-input v-model="formState.link" />
      </a-form-model-item>

      <a-button type="primary" html-type="submit">Создать</a-button>
    </a-form-model>
  </div>
</template>

<script>
import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import { formState, rules } from './constants'

export default {
  name: 'QRcodeCreate',
  data: () => ({
    formState,
    rules,
  }),
  methods: {
    onValidate(event) {
      event.preventDefault()
      this.validateFields()
    },
    toUppercase() {
      this.formState.name = this.formState.name?.toUpperCase()
    },
    async validateFields() {
      try {
        await this.$refs.form.validate()
        await this.createQRcode()
        showSuccessMsg('Вы создали QR код!')
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    async createQRcode() {
      const { id } = await this.$API.QRCode.createStaticQRCode(this.formState)
      await this.$router.push({ name: 'StaticQRCodeEdit', params: { id: id } })
    },
  },
  mounted() {
    this.formState = {
      name: null,
      description: null,
      link: null,
    }
  },
}
</script>

<style scoped></style>
