<template>
  <a-spin :spinning="isLoading">
    <h2>Мой профиль</h2>
    <a-form-model ref="form" :model="profile" @submit.prevent="onSaveProfile">
      <a-form-model-item prop="firstname" label="Имя">
        <a-input v-model="profile.first_name" />
      </a-form-model-item>
      <a-form-model-item prop="lastname" label="Фамилия">
        <a-input v-model="profile.last_name" />
      </a-form-model-item>
      <a-form-model-item prop="patronymic" label="Отчество">
        <a-input v-model="profile.patronymic" />
      </a-form-model-item>
      <a-form-model-item prop="sex" label="Пол">
        <a-radio-group v-model="profile.sex">
          <a-radio :disabled="true" value="male"> Мужчина</a-radio>
          <a-radio :disabled="true" value="female">Женщина</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item prop="email" label="EMAIL">
        <a-input v-model="profile.email" />
      </a-form-model-item>
      <a-form-model-item prop="iin" label="ИИН">
        <a-input :disabled="true" v-model="profile.iin" />
      </a-form-model-item>
      <phone-input
        :prop="'phone'"
        label="Телефон"
        v-model="profile.phone"
        :disabled="true"
      />
      <a-form-model-item prop="lang" label="Предпочитаемый язык">
        <a-tag>{{ langs[profile.lang] }}</a-tag>
      </a-form-model-item>
      <a-form-model-item prop="roles" label="Роли">
        <a-tag v-for="role in profile.roles" :key="role" color="green">
          {{ roles[role] }}
        </a-tag>
      </a-form-model-item>

      <div class="mt--2">
        <a-button type="primary" html-type="submit"> Сохранить </a-button>
        <a-popconfirm
          :title="`Вы уверены, что хотите сбросить пароль? На ваш номер ${profile.phone} будет отправлен OTP код`"
          ok-text="Да"
          cancel-text="Отмена"
          @confirm="onResetPassword"
        >
          <a-button class="ml--2"> Сбросить пароль </a-button>
        </a-popconfirm>
        <a-button class="ml--2" @click="onOpenChangePasswordModal">
          Сменить пароль
        </a-button>
      </div>
    </a-form-model>
    <change-password-modal
      v-model="isChangePassportModalVisible"
      type="change"
    />
    <reset-password-modal
      v-model="isResetPassportModalVisible"
      type="reset"
      :phone-number="profile.phone"
    />
  </a-spin>
</template>

<script>
import { roles, langs } from './constants'
import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import ChangePasswordModal from 'User/pages/Profile/ChangePasswordModal'
import ResetPasswordModal from 'User/pages/Profile/ChangePasswordModal'

export default {
  components: { ChangePasswordModal, ResetPasswordModal },
  data() {
    return {
      roles,
      langs,
      profile: JSON.parse(JSON.stringify(this.$store.getters['user/profile'])),
      isLoading: false,
      isChangePassportModalVisible: false,
      isResetPassportModalVisible: false,
    }
  },
  methods: {
    async onSaveProfile() {
      try {
        this.isLoading = true
        await this.$API.SSO.updateUserInfo(this.profile)
        showSuccessMsg('Профиль успешно обновлен')
      } catch (e) {
        showErrorMsg(e.message)
      } finally {
        this.isLoading = false
      }
    },
    async onResetPassword() {
      try {
        await this.$API.SSO.sendRecoveryOTPToPhone(this.profile.phone)
        showSuccessMsg(
          `Успешно отправлен OTP код на номер телефона ${this.profile.phone}`,
        )
        this.isResetPassportModalVisible = true
      } catch (e) {
        showErrorMsg(e.message)
      }
    },
    onOpenChangePasswordModal() {
      this.isChangePassportModalVisible = true
    },
  },
}
</script>

<style lang="scss" scoped>
.ant-form {
  max-width: 45vw;

  &-item {
    margin-bottom: 0;
  }
}
</style>
