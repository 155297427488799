<template>
  <div>
    <h2>Сопоставленные sku</h2>
    <a-form-model layout="inline" ref="form" :model="filter" @submit="onSearch">
      <div class="settings-wrap">
        <div>
          <p class="info">Показать с пустым значением</p>
          <a-radio-group
            :default-value="null"
            button-style="solid"
            v-model="filter.market"
            @change="onSearch"
          >
            <a-radio-button :value="null"> Все</a-radio-button>
            <a-radio-button
              v-for="(item, index) in MARKET_TYPES"
              :value="item.value"
              :key="index"
            >
              {{ item.title }}
            </a-radio-button>
          </a-radio-group>
        </div>
      </div>
    </a-form-model>
    <a-table
      :columns="columnNames"
      :data-source="dataSource"
      :scroll="{ x: true }"
      :locale="{ emptyText: 'Данные не найдены ):' }"
      :row-key="({ id }) => id"
      :loading="isLoading"
      :pagination="pagination"
      @change="onPageChange"
      bordered
    >
      <div slot="market_types" slot-scope="market_types, record">
        <market-bp-sku
          v-for="(item, index) in market_types"
          :market="item"
          :bp_sku="record.bp_sku"
          :key="index"
          @refreshList="initialize"
        />
      </div>
    </a-table>
  </div>
</template>

<script>
import { columnNames, MARKET_TYPES } from './constants'
import { showErrorMsg } from 'Core/utils'
import MarketBpSku from './MarketBpSku'

export default {
  name: 'MatchedSkuList',
  components: {
    MarketBpSku,
  },
  data() {
    return {
      columnNames,
      MARKET_TYPES,
      dataSource: [],
      isLoading: true,
      pagination: {
        current: 1,
        total: 0,
        defaultPageSize: 15,
      },
      filter: {
        page: 1,
        limit: 15,
        market: null,
      },
    }
  },
  methods: {
    initialize() {
      try {
        this.isLoading = true
        this.getMetchedSkuList()
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    async getMetchedSkuList() {
      const { sku_list, count } = await this.$API.BPM.getMatchedSkuList(
        this.filter,
      )
      this.pagination.total = count
      this.dataSource = sku_list
      this.isLoading = false
    },
    onPageChange({ current }) {
      this.filter.page = current
      this.pagination.current = current
      this.initialize()
    },
    async onSearch() {
      this.pagination.current = 1
      this.filter.page = 1
      this.initialize()
    },
  },
  mounted() {
    this.initialize()
  },
}
</script>

<style scoped></style>
