export const TRANSACTION_STATUSES = {
  completed: {
    value: 'completed',
    label: 'Выполненные заказы',
    variant: 'darkgreen',
    icon: 'check',
  },
  with_promocode: {
    value: 'with_promocode',
    label: 'Выполненные заказы с Промокодом',
    variant: 'darkorange',
    icon: 'rise',
  },
  pay: {
    value: 'pay',
    label: 'Оплаты через Pay',
    variant: 'blue',
    icon: 'swap',
  },
}
