<template>
  <div>
    <div class="settings-wrap">
      <h2>Список операции</h2>
    </div>
    <a-form-model layout="inline" ref="form" :model="filter" @submit="onFilter">
      <div class="settings-wrap">
        <a-radio-group
          button-style="solid"
          v-model="filterType"
          @change="onFilter"
        >
          <a-radio-button
            v-for="(item, index) in TRANSACTION_STATUSES"
            :value="item.value"
            :key="index"
          >
            <a-icon :type="item.icon" :style="{ color: item.variant }" />
            {{ item.label }}
          </a-radio-button>
        </a-radio-group>
      </div>
      <div class="settings-wrap">
        <a-form-model-item label="Дата транзакции:">
          <a-range-picker
            :locale="locale"
            :format="'DD MMM YYYY'"
            @change="onDateRangeChange"
            :placeholder="['С:', 'По:']"
            :value="[filter.date_from, filter.date_to]"
            :allow-clear="false"
          ></a-range-picker>
        </a-form-model-item>
        <a-form-item>
          <a-button-group>
            <a-button type="primary" html-type="submit">Поиск</a-button>
            <a-button type="dashed" @click="dropFilter"> сбросить </a-button>
          </a-button-group>
        </a-form-item>
      </div>
    </a-form-model>
    <a-table
      :columns="columnNames"
      :data-source="dataSource"
      :scroll="{ x: true }"
      :locale="{ emptyText: 'Данные за выбранный период не найдены ):' }"
      :row-key="({ sku }) => sku"
      :loading="isLoading"
      :pagination="pagination"
      @change="onPageChange"
      bordered
    >
      <div slot="orderId" slot-scope="orderId">
        <a :href="`#order/${orderId}`" target="_blank">
          {{ orderId }}
          <a-icon type="link" />
        </a>
      </div>
      <div slot="merchantOrderId" slot-scope="merchantOrderId, record">
        <a
          :href="`#partner/${record.merchant_id}/order/${merchantOrderId}`"
          target="_blank"
        >
          {{ merchantOrderId }}
          <a-icon type="link" />
        </a>
      </div>
      <div slot="totalPrice" slot-scope="totalPrice">
        {{ totalPrice | currency }}
      </div>
      <div slot="payment" slot-scope="payment">
        <p>
          Тип:
          <template v-if="PaymentTypes[payment.type]">
            <a-tag :color="PaymentTypes[payment.type].variant">
              <a-icon :type="PaymentTypes[payment.type].icon"></a-icon>
              {{ PaymentTypes[payment.type].label }}
            </a-tag>
          </template>
          <template v-else>
            Неизвестный способ оплаты [{{ payment.type }}]
          </template>
        </p>
        <p>
          Статус:
          <template v-if="PAYMENT_STATUSES[payment.status]">
            <a-tag :color="PAYMENT_STATUSES[payment.status].variant">
              <a-icon :type="PAYMENT_STATUSES[payment.status].icon"></a-icon>
              {{ PAYMENT_STATUSES[payment.status].label }}
            </a-tag>
          </template>
          <template v-else>
            Неизвестный статус [{{ payment.status }}]
          </template>
        </p>
      </div>
      <div slot="promo_code" slot-scope="promo_code">
        <a-tag v-if="promo_code" color="darkorange">{{ promo_code }}</a-tag>
        <a-tag v-else color="orange">Не использовано</a-tag>
      </div>
      <div slot="endDate" slot-scope="endDate">
        <a-tag v-if="endDate" color="darkgreen">{{
          endDate | formatDate
        }}</a-tag>
        <a-tag v-else>Не завершен</a-tag>
      </div>
    </a-table>
  </div>
</template>

<script>
import { showErrorMsg } from 'Core/utils'

import { columnNames } from './constants'
import locale from 'ant-design-vue/es/date-picker/locale/ru_RU'
import moment from 'moment'

import { TRANSACTION_STATUSES } from 'Core/types/transaction'
import { PaymentTypes } from 'Core/constants'
import { PAYMENT_STATUSES } from 'Core/types/order'

export default {
  name: 'FinanceList',
  data() {
    return {
      columnNames,
      locale,
      TRANSACTION_STATUSES,
      PAYMENT_STATUSES,
      PaymentTypes,
      filterType: 'completed',
      isLoading: true,
      dataSource: [],
      pagination: {
        current: 1,
        total: 0,
        defaultPageSize: 10,
      },
      filter: {
        order_state: 'completed',
        promo_code_used: null,
        date_from: null,
        date_to: null,
        page: 1,
        limit: 10,
      },
    }
  },
  methods: {
    initialize() {
      this.isLoading = true
      try {
        this.getTransactionList()
        this.isLoading = false
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isLoading = false
      }
    },
    async getTransactionList() {
      const { report_orders, count } = await this.$API.OMS.getReportInfoList(
        this.filter,
      )
      this.pagination.total = count
      this.dataSource = report_orders
    },
    onDateRangeChange(date) {
      this.filter.date_from = moment(date[0]).format('YYYY-MM-DDT00:00:00Z')
      this.filter.date_to = moment(date[1]).format('YYYY-MM-DDT23:59:59Z')
      this.filterHandler()
    },
    onFilter(event) {
      event.preventDefault()
      switch (this.filterType) {
        case 'with_promocode': {
          this.filter.promo_code_used = true
          this.filter.order_state = 'completed'
          break
        }
        case 'pay': {
          this.filter.order_state = 'paid'
          this.filter.promo_code_used = null
          break
        }
        default: {
          // is "completed" type
          this.filter.order_state = 'completed'
          this.filter.promo_code_used = null
        }
      }
      this.filterHandler()
    },
    async filterHandler() {
      try {
        this.isLoading = true
        this.pagination.current = 1
        this.filter.page = 1
        await this.getTransactionList()
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isLoading = false
      }
    },
    onPageChange({ current }) {
      this.filter.page = current
      this.pagination.current = current
      this.initialize()
    },
    dropFilter() {
      this.pagination.current = 1
      this.filter = {
        search: null,
        page: 1,
        limit: 10,
      }
      this.filter = {
        order_state: 'completed',
        promo_code_used: null,
        date_from: moment(new Date())
          .subtract(1, 'day')
          .format('YYYY-MM-DDT00:00:00Z'),
        date_to: moment(new Date()).format('YYYY-MM-DDT23:59:59Z'),
        page: 1,
        limit: 10,
      }
      this.initialize()
    },
  },
  created() {
    this.filter.date_from = moment(new Date())
      .subtract(1, 'day')
      .format('YYYY-MM-DDT00:00:00Z')
    this.filter.date_to = moment(new Date()).format('YYYY-MM-DDT23:59:59Z')
    this.initialize()
  },
}
</script>

<style scoped lang="scss">
.range-picker {
  width: 30px !important;
  border-left: 0;
  pointer-events: none;
  background-color: #fff;
}
</style>
