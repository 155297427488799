<template>
  <div>
    <div class="settings-wrap">
      <h2>Категории 3 уровня</h2>
      <router-link :to="{ name: 'ComissionView' }">
        <a-button>Назад</a-button>
      </router-link>
    </div>

    <a-form-model layout="inline" :model="formState" @submit.prevent="onFilter">
      <div class="settings-wrap">
        <a-form-model-item
          label="Поиск по категориям 3 уровня"
          class="search-input"
        >
          <a-input v-model="formState.search" />
        </a-form-model-item>
        <a-form-model-item label="Только неназначенные">
          <a-checkbox v-model="formState.onlyAssaigned"> </a-checkbox>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" html-type="submit"> Применить </a-button>
          <a-button class="ml--1" @click="onClear"> Очистить </a-button>
        </a-form-model-item>
      </div>
    </a-form-model>

    <a-table
      class="mt--2"
      :columns="columnNames"
      :data-source="isFiltered ? filteredList : mappedList"
      :scroll="{ x: true }"
      :locale="{ emptyText: 'Данные не найдены ):' }"
      :row-key="({ slug }) => slug"
      :loading="isLoading"
      bordered
    >
      <div slot="slug" slot-scope="slug, item">
        {{ getParentCategories(slug) || item.category_title.ru }}
      </div>
      <div slot="rate" slot-scope="rate, record">
        <a-tag color="green" class="rate-box">
          <span>{{ rate / 100 }}</span>
          %
        </a-tag>
        <a-icon
          @click="editOrSetHandler(record)"
          class="edit-box"
          type="edit"
        />
      </div>
      <div slot="updatedAt" slot-scope="updatedAt">
        <template v-if="updatedAt">{{ updatedAt | formatDate }}</template>
        <template v-else>Еще не назначали.</template>
      </div>
    </a-table>

    <a-modal
      v-model="modalIsShown"
      title="Укажите значение от 0-100%"
      cancel-text="Отмена"
      @ok="handleUpdateCategoryRate"
    >
      <h2 v-if="currentCat.category_title">
        {{ currentCat.category_title.ru }}
      </h2>
      <a-form-model-item label="Сумма комиссии">
        <a-input-number
          v-model="rate"
          :min="0"
          block
          :formatter="(value) => `${value}%`"
          :parser="(value) => value.replace('%', '')"
          :max="100"
          :precision="2"
        />
      </a-form-model-item>
    </a-modal>
  </div>
</template>

<script>
import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import Fuse from 'fuse.js'

export default {
  name: 'ComissionNew',
  data() {
    return {
      isLoading: true,
      modalIsShown: false,
      currentCat: {},
      categoryII: [],
      categoryIII: [],
      rate: 0,
      columnNames: [
        {
          title: 'Категория',
          dataIndex: 'slug',
          key: 'slug',
          scopedSlots: { customRender: 'slug' },
          align: 'right',
        },
        {
          title: 'Наша комиссия',
          dataIndex: 'value',
          key: 'value',
          scopedSlots: { customRender: 'rate' },
          align: 'right',
          width: '140px',
        },
        {
          title: 'Дата обновления',
          dataIndex: 'updated_at',
          key: 'updated_at',
          scopedSlots: { customRender: 'updatedAt' },
          align: 'right',
          width: '170px',
        },
      ],
      dataSource: [],
      mappedList: [],
      formState: {
        search: '',
        onlyAssaigned: false,
      },
      filteredList: [],
      isFiltered: false,
      fuseConfig: {
        isCaseSensitive: false,
        keys: ['category_title.ru', 'category_title.kz'],
      },
      fuse: null,
    }
  },
  methods: {
    async initialize() {
      try {
        this.isLoading = true
        await this.getCommission()
        this.categoryIII = await this.$API.Storefront.getCategoryList({
          level: 3,
        })
        this.setCategoryComissions()
        await this.getCategoryTree()
        this.isLoading = false
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    async getCommission() {
      try {
        const { commissions } = await this.$API.Commissions.getCommissionsList({
          page: 1,
          limit: 1000,
        })
        this.dataSource = commissions
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    setCategoryComissions() {
      this.mappedList = this.categoryIII.map((item) => {
        const isSetted = this.getSetedCommissionInfo(item.title.ru)
        if (isSetted) {
          return { ...isSetted, slug: isSetted.category }
        }
        return {
          category_title: {
            ru: item.title.ru,
          },
          slug: item.slug,
          updated_at: null,
          value: 500,
        }
      })
      this.fuse = new Fuse(this.mappedList, this.fuseConfig)
    },
    getSetedCommissionInfo(category_name) {
      return this.dataSource.find(
        (item) => item.category_title.ru === category_name,
      )
    },
    async getCategoryTree() {
      try {
        const tree = await this.$API.Storefront.getCategoryTree()
        for (const categoryI of tree) {
          this.categoryII.push(...categoryI.children)
        }
      } catch (e) {
        showErrorMsg(e.message)
      }
    },
    getParentCategories(category_name) {
      const category = this.categoryII.find((c2) =>
        c2.children.find((c3) => c3.slug === category_name),
      )
      if (!category) return ''
      const c3 = category.children.find((item) => item.slug === category_name)
      return `${category.parent?.title?.ru} - ${category.title?.ru} - ${c3.title?.ru}`
    },
    editOrSetHandler(cat) {
      this.modalIsShown = true
      this.currentCat = cat
      this.rate = cat.value / 100
    },
    onFilter() {
      const { search, onlyAssaigned } = this.formState
      if (!search && !onlyAssaigned) {
        this.isFiltered = false
        return
      }
      this.filteredList = this.mappedList

      if (search) this.onSearch(search)
      if (onlyAssaigned) this.onShowOnlyAssigned()
      this.isFiltered = true
    },
    onSearch(search) {
      this.filteredList = this.fuse.search(search).map((item) => {
        return item.item
      })
    },
    onShowOnlyAssigned() {
      this.filteredList = this.filteredList.filter((item) => !item.updated_at)
    },
    onClear() {
      this.formState.search = ''
      this.formState.onlyAssaigned = false
      this.isFiltered = false
    },
    async handleUpdateCategoryRate() {
      const params = {
        value: this.rate ? this.rate * 100 : 0,
        category_title: {
          ru: this.currentCat.category_title.ru,
          kz: this.currentCat.category_title.kz,
        },
        category: this.currentCat.slug,
        merchant_id: null,
      }
      if (this.currentCat.updated_at) {
        await this.$API.Commissions.updateCommission(this.currentCat.id, params)
        showSuccessMsg('Успешно обновили комиссию')
      } else {
        await this.$API.Commissions.createCustomCommission(params)
        showSuccessMsg('Вы добавили глобальную комиссию')
      }
      this.modalIsShown = false
      await this.initialize()
      if (this.isFiltered) this.onFilter()
    },
  },
  mounted() {
    this.initialize()
  },
}
</script>

<style scoped></style>
