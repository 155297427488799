<template>
  <div>
    <div class="settings-wrap">
      <h2>Редактирование баннера</h2>
      <a-button
        @click="
          () =>
            $router.push({
              name: 'BannerList',
            })
        "
      >
        {{ $t('common.back') }}
      </a-button>
    </div>
    <a-spin :spinning="getBannerIsLoading">
      <a-form-model
        v-if="bannerForm"
        ref="form"
        :model="bannerForm"
        :rules="bannerFormRules"
        @submit="onSubmit"
      >
        <a-form-model-item prop="info.title" label="Заголовок">
          <a-input v-model="bannerForm.info.title" />
        </a-form-model-item>
        <a-form-model-item prop="info.text" label="Подзаголовок">
          <a-input v-model="bannerForm.info.text" />
        </a-form-model-item>
        <a-form-model-item prop="color.background" label="Цвет фона">
          <a-input v-model="bannerForm.color.background" class="color__input">
            <template slot="addonBefore">
              <div
                :style="{ backgroundColor: bannerForm.color.background }"
                class="color__preview"
              ></div>
            </template>
          </a-input>
        </a-form-model-item>
        <a-form-model-item prop="color.text" label="Цвет текста">
          <a-input v-model="bannerForm.color.text" class="color__input">
            <template slot="addonBefore">
              <div
                :style="{ backgroundColor: bannerForm.color.text }"
                class="color__preview"
              ></div>
            </template>
          </a-input>
        </a-form-model-item>
        <a-form-model-item prop="image" label="Картинка баннера">
          <upload-files
            @getImageParams="getImageParams"
            :img-url="bannerForm.image"
            placeholder-text="Загрузить картинку"
          />
        </a-form-model-item>
        <a-form-model-item prop="url" label="Ссылка">
          <a-input v-model="bannerForm.url" />
        </a-form-model-item>

        <a-form-model-item prop="search.type" label="Тип переадресации">
          <a-select
            v-model="bannerForm.search.type"
            placeholder="Выберите тип переадресации"
          >
            <a-select-option
              v-for="(item, index) in searchTypeList"
              :key="index"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item
          v-if="bannerForm.search.type === 'product'"
          label="Цель переадресации. SKU продутка"
        >
          <a-input v-model="bannerForm.search.id"></a-input>
        </a-form-model-item>

        <a-form-model-item
          v-else-if="bannerForm.search.type === 'category'"
          label="Цель переадресации"
        >
          <a-input v-model="bannerForm.search.id" disabled></a-input>
          <category-selector
            :drawer-title="'Выберите категорию для ссылки'"
            :drawer-width="'85%'"
            v-model="bannerForm.search.id"
          />
        </a-form-model-item>

        <a-form-model-item v-else label="Цель переадресации. Ссылка">
          <a-input v-model="bannerForm.search.id"></a-input>
        </a-form-model-item>

        <a-form-model-item prop="switch_text" label="Текст замена">
          <a-input v-model="bannerForm.switch_text" />
        </a-form-model-item>

        <a-form-model-item prop="alt" label="Описание картинки">
          <a-input v-model="bannerForm.alt" />
        </a-form-model-item>

        <a-form-model-item prop="weight" label="Приоритет в списке">
          <a-input-number v-model="bannerForm.weight" :min="1" />
        </a-form-model-item>

        <a-form-model-item prop="position" label="Позиция">
          <a-select
            v-model="bannerForm.position"
            placeholder="Выберите позицию"
          >
            <a-select-option
              v-for="(position, index) in positionTypes"
              :key="index"
              :value="position"
            >
              {{ position }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item prop="platforms" label="Устройства">
          <a-select
            mode="multiple"
            :default-value="['web']"
            v-model="bannerForm.platforms"
            placeholder="Выберите устройства"
          >
            <a-select-option
              v-for="(device, index) in deviceList"
              :key="index"
              :value="device"
            >
              {{ device }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item prop="active" label="Статус">
          <a-switch v-model="bannerForm.active" />
        </a-form-model-item>
        <a-button type="primary" html-type="submit">Сохранить</a-button>
        <a-popconfirm
          title="Вы уверены?"
          ok-text="Да"
          cancel-text="Отмена"
          @confirm="onDeleteBanner"
        >
          <a-icon slot="icon" type="question-circle-o" style="color: red" />
          <a-button type="danger"> Удалить</a-button>
        </a-popconfirm>
      </a-form-model>
    </a-spin>
  </div>
</template>

<script>
import UploadFiles from 'Core/components/Files/UploadFiles.vue'

import { showErrorMsg, showSuccessMsg } from 'Core/utils'

import { bannerFormRules } from 'Content/pages/Banner/BannerCreate/constants'
import CategorySelector from 'Core/components/Selector/CategorySelector'

export default {
  components: {
    UploadFiles,
    CategorySelector,
  },
  data() {
    return {
      deviceList: ['WEB', 'IOS', 'ANDROID', 'WEB_MOBILE'],
      positionTypes: ['top', 'bottom', 'middle', 'left', 'right'],
      searchTypeList: [
        {
          label: 'Продукт',
          value: 'product',
        },
        {
          label: 'Категория',
          value: 'category',
        },
        {
          label: 'Ссылка',
          value: 'web',
        },
      ],
      bannerFormRules,
      getBannerIsLoading: false,
      bannerForm: null,
      submitIsLoading: false,
    }
  },
  computed: {
    bannerID() {
      return this.$route.params.id
    },
  },
  created() {
    this.getBannerById()
  },
  methods: {
    async getBannerById() {
      try {
        this.getBannerIsLoading = true
        this.bannerForm = await this.$API.Banner.getBannerById(this.bannerID)
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.getBannerIsLoading = false
      }
    },
    getImageParams(params) {
      this.bannerForm.image = params
    },
    async onSubmit(event) {
      event.preventDefault()
      this.$refs.form.validate(async (result) => {
        if (result) {
          try {
            this.submitIsLoading = true
            await this.$API.Banner.updateBannerById(
              this.bannerID,
              this.bannerForm,
            )
            showSuccessMsg('Баннер обновлен успешно!')
            await this.$router.push({
              name: 'BannerList',
            })
          } catch (error) {
            showErrorMsg(error.message)
          } finally {
            this.submitIsLoading = false
          }
        }
      })
    },
    async onDeleteBanner() {
      try {
        await this.$API.Banner.deleteBannerById(this.bannerID)
        showSuccessMsg('Баннер удален!')
        await this.$router.push({
          name: 'BannerList',
        })
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-btn {
    margin-top: 30px;
  }
}

.ant-btn-danger {
  margin: 20px 15px 0;
}

.color {
  &__preview {
    width: 15px;
    height: 15px;
    border: 1px solid black;
  }
}
</style>
