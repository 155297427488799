




















































import { Component, Vue } from 'vue-property-decorator'
import { tableColumns } from './constants'
import { showErrorMsg } from 'Core/utils'

@Component
class DynamicQRCodeList extends Vue {
  tableColumns = tableColumns
  QRCodeList = []
  isLoading = false
  pagination = {
    current: 1,
    total: 0,
    defaultPageSize: 10,
  }
  queryParams = {
    limit: 10,
    page: 1,
  }

  async getQRCodeList() {
    try {
      this.isLoading = true
      const { data, count } = await this.$API.QRCode.getDynamicQRCodeList(
        this.queryParams,
      )
      this.QRCodeList = data
      this.pagination.total = count
    } catch (error) {
      showErrorMsg(error.message)
    } finally {
      this.isLoading = false
    }
  }

  onPageChange({ current }) {
    this.queryParams.page = current
    this.pagination.current = current
    this.getQRCodeList()
  }

  gotoCreateQR() {
    this.$router.push({
      name: 'DynamicQRCodeCreate',
    })
  }

  addPrefix(url) {
    // если укажут url типа youtube.com вместо https://youtube.com
    // браузер будет перенаправлять по относительному пути. Чтобы такого не было, добавляем префикс(если его нет)
    // eslint-disable-next-line
    return url.match(/^.{3,5}\:\/\//) ? url : `http://${url}`
  }

  created() {
    this.getQRCodeList()
  }
}

export default DynamicQRCodeList
